import { Component, OnInit, ViewChild, ViewChildren, ViewEncapsulation, EventEmitter, Output, OnDestroy, TemplateRef } from '@angular/core';
import { AppService } from '../../../core/services/base.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoggingService } from '../../../core/services/log.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorService } from '../../../core/translator/translator.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { ColorsService } from '../../../shared/colors/colors.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { SalesService } from '../../../services/sales.service';
import { RateService } from '../../../services/rate.service';
import { SharedService } from '../../../shared/shared.service';
import { ExcelService } from '../../../shared/excel.service';
import * as moment from 'moment';
import * as localization from 'moment/locale/it';
import swal from 'sweetalert2';
import { SellerService } from '../../../services/seller.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RefundType } from '../../../core/classes/interfaces.interface';
import { DecimalPipe } from '@angular/common';
import { Subscription } from 'rxjs';

moment.locale('it', localization);

@Component({
  selector: 'app-rate-analysis',
  templateUrl: './rate-analysis.component.html',
  styleUrls: ['./rate-analysis.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RateAnalysisComponent implements OnInit, OnDestroy {
  @ViewChild('dataTable') table: any;
  @ViewChildren('table') subTable: any;
  @ViewChild('refundModal') modalTemplate : TemplateRef<any>;
  @ViewChild('infoModal') infoModalTemplate: TemplateRef<any>;

  allStatus: any = [
    { "name": "attiva" },
    { "name": "aperta" },
  ]

  @Output() deletedChip = new EventEmitter<any>();

  activePage: String = "sa";
  rows: any[] = [];
  totalRows: number = 0;
  queryParamsSearch: any;
  queryParamsDate: any;
  filterQueryParameterUrl: any;

  selectedUser : any;

  states = [
    { 'state': 'final', 'formStatusValid': false },
    { 'state': 'initial', 'formStatusValid': false },
    { 'state': 'initial', 'formStatusValid': false },
    { 'state': 'initial', 'formStatusValid': false }];

  intervalOptions = [
    {
      "id": 1, "name": "advance-search.one", "removedMonth": 1
    }, 
    {
      "id": 2, "name": "advance-search.three", "removedMonth": 6
    }
    , {
      "id": 3, "name": "advance-search.six", "removedMonth": 12
    }
  ]

  selectOptions = [
    {
      "id": 1, "name": "Amministratore", "type": "word", "typeOfSearch": "level"
    }, {
      "id": 2, "name": "Rivenditore", "type": "word", "typeOfSearch": "level"
    }, {
      "id": 3, "name": "Gestore", "type": "word", "typeOfSearch": "level"
    },
    {
      "id": 4, "name": "Responsabile", "type": "word", "typeOfSearch": "level"
    },
    {
      "id": 5, "name": "Agente", "type": "word", "typeOfSearch": "level"
    }
  ]

  options: any = {};
  allRates: any;
  tableRecordNumbers: number;
  tablePage: number = 1;

  newVal : boolean;
  oldVal : boolean = false; 
  includiScaduti : boolean = false;
  expiredOptions : boolean = true;
  viewPaymentLink : boolean = false;
  profile : any;
  merchantDetail : any;

  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };
  enableSendReminder: boolean;

  searchForm: FormGroup;
  sortForm: FormGroup;

  modalRef : BsModalRef

  minInstalmentAmount: number = 0;
  saleFrozenMaxDays: number = 0;

  instalmentToBeRefunded: any = null;
  refundForm = this.formBuilder.group({
    type: [RefundType.FULL],
    amount: [null, [Validators.min(this.minInstalmentAmount)]],
  })

  RefundType = RefundType;

  notifySelectedUserChange : Subscription;

  infoForm: FormGroup;
  hideInstalmentInfo: boolean;

  constructor(
    private excelService: ExcelService,
    private sharedService: SharedService,
    protected appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    public colors: ColorsService,
    public http: HttpClient,
    public settings: SettingsService,
    private salesService: SalesService,
    private rateService: RateService,
    private translate: TranslateService,
    private translator: TranslatorService, 
    private logger : LoggingService , 
    private sellerService : SellerService,
    private configurationService: ConfigurationService,
    private modalService : BsModalService,
    private formBuilder: FormBuilder,
    private decimalPipe: DecimalPipe) {
      this.tableRecordNumbers = parseInt(localStorage.getItem('tableRecordNumbers'), null);

      // Overrides default total/empty messages
      translate.get("datatable_message.lista_totali_righe").subscribe((res : string ) => this.my_messages.totalMessage = res );
      translate.get("datatable_message.lista_vuota_righe").subscribe((res : string ) => this.my_messages.emptyMessage = res);

      this.searchForm = this.formBuilder.group({
        customerName: [null],
        customerSurname: [null],
        customerEmail: [null],
        amountFrom: [null],
        amountTo: [null],
        //dateRangeExpiration: [{ startDate: moment(), endDate: moment().add(30, 'days') }],
        //dateFromExpiration: [{ startDate: moment(), endDate: moment() }],
        //dateToExpiration: [{ startDate: moment().add(30, 'days'), endDate: moment().add(30, 'days') }],
        dateRangeExpiration: [[ moment().toDate(), moment().add(30, 'days').toDate() ]],
        //dateRangeCollection: [null],
        //dateFromCollection: [null],
        //dateToCollection: [null],
        dateRangeCollection: [null],
        //dateRangePayment: [null],
        //dateFromPayment: [null],
        //dateToPayment: [null],
        dateRangePayment: [null],
        status: [null],
        userEmail: [null],
        idMerchant: [null]
      });
      
      if (localStorage.getItem("searchForm")) {
        const searchForm = JSON.parse(localStorage.getItem("searchForm"))
        for (let key in searchForm) {
          /*if (key.startsWith('date') && searchForm[key]) {
            searchForm[key] = { startDate: moment(searchForm[key]), endDate: moment(searchForm[key]) }
          }*/
          if (key.startsWith('dateRange') && searchForm[key]) {
            searchForm[key] = [ ...searchForm[key].map(value => moment(value).toDate()) ]
          }
        }
        this.searchForm.patchValue({ ...searchForm })
        this.searchForm.markAsDirty();
        localStorage.removeItem("searchForm");
      }

      this.sortForm = this.formBuilder.group({
        viewExpired: [false],
        viewRefunded: [false],
        viewDisputed: [false],
        statusDisputed: [null]
      });

      if (localStorage.getItem("sortForm")) {
        const sortForm = JSON.parse(localStorage.getItem("sortForm"))
        this.sortForm.patchValue({ ...sortForm })
        localStorage.removeItem("sortForm");
      }
      
      this.hideInstalmentInfo = localStorage.getItem('hideInstalmentInfo') ?
        JSON.parse(localStorage.getItem('hideInstalmentInfo')) : false;

      this.infoForm = this.formBuilder.group({
        hideInfo: [this.hideInstalmentInfo, Validators.requiredTrue]
      });
  }

  ngOnInit() {
    this.profile = localStorage.getItem('profile')
    localStorage.setItem( "saleDate" , "1" )

    /*if (!this.queryParamsDate){
      this.queryParamsDate = this.initFirstCallRangeDates();
    }*/
    // this.initRateTable()
    this.selectedUser = JSON.parse( localStorage.getItem("selectedUser") )
    
    /*this.getAllRates( "startDateExpiration=" + moment().format("YYYY-MM-DD") +
      "&endDateExpiration=" + moment().add(30, 'days').format("YYYY-MM-DD"),
      this.selectedUser ? this.selectedUser.idMerchant : null );*/
    this.prepareForSearch();

    this.notifySelectedUserChange = this.sharedService.selectedUserChanged.subscribe((value) => {
      this.selectedUser = JSON.parse( localStorage.getItem("selectedUser") )

      if (this.selectedUser) {
        this.searchForm.controls.idMerchant.setValue(this.selectedUser.idMerchant);
        this.searchForm.controls.idMerchant.disable();
      } else {
        this.searchForm.controls.idMerchant.reset();
        this.searchForm.controls.idMerchant.enable();
      }

      this.prepareForSearch();
    })

    if( localStorage.getItem("profile") != "SUPERADMIN" && localStorage.getItem("profile") != "RESELLER") {
      this.getMerchantDetail()
    } else {
      this.canViewPaymentLink()
    }

    this.getMinInstalmentAmountConfiguration();
    this.getSaleFrozenMaxDaysConfiguration();

    setTimeout(() => {
      if (!this.hideInstalmentInfo) {
        this.openInfoModal();
      }
    });
  }

  ngDoCheck() {
    this.newVal = this.hasSelectedAlias()
    
    if ( this.isValChanged( this.oldVal , this.newVal) ){
      this.oldVal = this.newVal
      // this.getAllRates( null , this.queryParamsDate , this.selectedUser ? this.selectedUser.idMerchant : null )
    } 
  }
  
  get language() {
    return this.translator.getLanguage();
  }

  initRateTable( isFromEmit = null ) {
    this.options = {};
    if( !localStorage.getItem("saleDate") ) {
      this.filterQueryParameterUrl = "";
    }

    this.selectedUser = JSON.parse(localStorage.getItem("selectedUser"))
    if (this.selectedUser){
      this.options.userSelected = this.selectedUser
    }  

    if( localStorage.getItem("saleDate")) {
      this.options.dates = this.initFirstCallRangeDates( "chip" )
    }

    let statusUrl = localStorage.getItem("saleStatus");
    if (statusUrl == 'insoluti') {
      this.options.status = 'INSOLUTA'
      this.filterQueryParameterUrl = "status=INSOLUTA"
      // this.getAllRates(null, this.initFirstCallRangeDates(), this.selectedUser ? this.selectedUser.idMerchant : null);
    } else {
      if( isFromEmit == null ) {
        this.queryParamsDate = this.initFirstCallRangeDates()
      } else if ( isFromEmit != null ) {
        if( localStorage.getItem("saleDate") == null ) {
          this.queryParamsDate = null
        }
      }
      // this.getAllRates( null , this.queryParamsDate ? this.queryParamsDate : null , this.selectedUser ? this.selectedUser.idMerchant : null )
    }
    /* else {
      if (!this.queryParamsDate)
        this.queryParamsDate = this.initFirstCallRangeDates();
      this.hasSelectedAlias()
      this.getAllRates(null, this.queryParamsDate, this.selectedUser ? this.selectedUser.idMerchant : null);
    } */
  }

  async getAllRates(queryParams = null, id = null, exporting = false) {

    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    if (!queryParams) {
      queryParams = '';
    } else {
      queryParams += '&';
    }

    if (!exporting) {
      queryParams += 'paging=true&page=' + this.tablePage + '&rows=' + this.tableRecordNumbers;
    }
    
    const res = await this.rateService.getAllInstalments(queryParams, id).toPromise();
    let results = [];
    if (res) {
      results = res.results;
      if (!exporting) {
        // this.rates = res.results;
        this.allRates = res.results.map( x => {
          if( (x.instalmentStatus == 'PLANNED' || x.instalmentStatus == 'ERROR' ) && (x.saleStatus == 'ACTIVE' || x.saleStatus == 'NEW')) {
            x['closable'] = true;
          } else {
            x['closable'] = false;
          }
          return x;
        });
        /* res.results.forEach(element => {
          if (element.expiryDate)
            element.expiryDate = this.sharedService.formatDate(new Date(element.expiryDate))
          if (element.tsPayment)
            element.tsPayment = this.sharedService.formatDate(new Date(element.tsPayment))
          if (element.startDate)
            element.startDate = this.sharedService.formatDate(new Date(element.startDate))
          if (element.endDate)
            element.endDate = this.sharedService.formatDate(new Date(element.endDate))
          if (element.saleDate)
            element.saleDate = this.sharedService.formatDate(new Date(element.saleDate))
        }); */
        // this.rates = [...this.rates]
        this.rows = this.allRates;
        this.totalRows = res.total;
        /*if ( !this.includiScaduti ) {
          this.rates = this.excludeExpiredRates();
        }*/
        /*if (!this.sortForm.controls.viewExpired.value || this.sortForm.controls.viewRefunded.value || this.sortForm.controls.viewDisputed.value) {
          this.rows = this.excludeFlagRates();
        }
        this.rows = [...this.rows];*/
      }
    }
    swal.close();
    return results;
  }

  getMinInstalmentAmountConfiguration() {
    this.configurationService.getConfiguration('MINIMUM_INSTALMENT_AMOUNT').subscribe(res => {
      this.minInstalmentAmount = +res.valueConfiguration;
    });
  }

  getSaleFrozenMaxDaysConfiguration() {
    this.configurationService.getConfiguration('SALE_FROZEN_MAX_DAYS').subscribe(res => {
      this.saleFrozenMaxDays = +res.valueConfiguration;
    });
  }

  getSaleRefundStatus(row) {
    if (row.status == 'active' || row.status == 'closed' || row.status == 'canceled') {
      if (row.amount == row.refundedAmount) {
        return 'refunded_fully';
      } else if (row.refundedAmount > 0) {
        return 'refunded_partially';
      }
    }
    return null;
  }

  isSaleRefundPending(row) {
    return (row.status == 'active' || row.status == 'closed' || row.status == 'canceled') && row.refundTotalAmount > row.refundedAmount;
  }

  getInstalmentStatus(row) {
    if (row.instalmentStatus == 'PAID' || row.instalmentStatus == 'ONGOING_INQUIRY' || row.instalmentStatus == 'CLOSED_INQUIRY') {
      if (row.amount == row.refundedAmount) {
        return 'REFUNDED_FULLY';
      } else if (row.refundedAmount > 0) {
        return 'REFUNDED_PARTIALLY';
      }
    } else if (this.isExpired(row)) {
      return 'EXPIRED';
    }
    return row.instalmentStatus;
  }

  isRefundPending(row) {
    return (row.instalmentStatus == 'PAID' || row.instalmentStatus == 'ONGOING_INQUIRY' || row.instalmentStatus == 'CLOSED_INQUIRY') &&
      row.refundTotalAmount > row.refundedAmount;
  }

  isSalePaymentMethodGoingToExpire(row) {
    if (!row.paymentMethodType || !row.paymentMethodExpMonth || !row.paymentMethodExpYear) {
      return false;
    }
    if (row.status != 'active' ||
      (row.paymentType != 'RATEIZZATO' && row.paymentType != 'RICORRENTE')) {
      return false;
    }
    const lastExpirationDate = moment().toDate();
    return (row.paymentMethodExpYear == lastExpirationDate.getFullYear() && 
      row.paymentMethodExpMonth == lastExpirationDate.getMonth() + 1);
  }

  isSalePaymentMethodExpired(row) {
    if (!row.paymentMethodType || !row.paymentMethodExpMonth || !row.paymentMethodExpYear) {
      return false;
    }
    if (row.status != 'active' ||
      (row.paymentType != 'RATEIZZATO' && row.paymentType != 'RICORRENTE')) {
      return false;
    }
    const lastExpirationDate = moment().toDate();
    return (row.paymentMethodExpYear < lastExpirationDate.getFullYear() ||
      (row.paymentMethodExpYear == lastExpirationDate.getFullYear() && 
        row.paymentMethodExpMonth < lastExpirationDate.getMonth() + 1));
  }

  excludeExpiredRates() {
    let result : any = []
    this.allRates.forEach( element => {
      if( this.isExpired( element ) ) {
      } else {
        result.push(element)
      }
    })
    return result
  }

  excludeFlagRates() {
    let result : any = []
    this.allRates.forEach( element => {
      if( (!this.sortForm.controls.viewExpired.value && this.isExpired( element )) ||
        (this.sortForm.controls.viewRefunded.value && !this.isRefunded( element )) ||
        (this.sortForm.controls.viewDisputed.value && !this.isDisputed( element )) ) {
      } else {
        result.push(element)
      }
    })
    return result
  }

  onPage(event) {
    if (this.tablePage != event.offset + 1) {
      this.tablePage = event.offset + 1;
      this.getAllRates( this.getQueryParams(),
        this.selectedUser ? this.selectedUser.idMerchant : this.getIdMerchant() );
    }
  }

  async toggleExpandRow(row : any = [] , expanded) {
    if (!expanded) {
      await this.getRateDetails(row);
      await this.getSalesInstallments(row);
    }
    this.table.rowDetail.toggleExpandRow(row);
    setTimeout(() => {
      const subTable = this.subTable.filter(subTable => subTable.element.id == row.id)[0];
      row["salesInstallments"].forEach(saleInstalment => {
        if (saleInstalment.instalmentRefs && saleInstalment.instalmentRefs.length) {
          if (subTable) {
            setTimeout(() => {
              subTable.rowDetail.toggleExpandRow(saleInstalment);
            })
          }
        }
      })
    })
  }

  async getRateDetails(row) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    let res = await this.salesService.getRateDetails(row.idSale).toPromise()
    row["rateDetails"] = res;
    swal.close();
    return res;
  }

  async getSalesInstallments(row) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    let res = await this.salesService.getSalesDetails(row.idSale).toPromise();
    row["salesInstallments"] = res.results;
    swal.close();
    return res.results;
  }

  canViewDetails(row) {
    return row.instalmentStatus != 'REFUND_DISPUTE' && row.instalmentStatus != 'REFUNDED_DISPUTE';
  }

  searchEvent(event) {
    console.log('searchEvent - event ', event);
    this.queryParamsDate = null;
    this.queryParamsSearch = this.getQueryParamsOfSearch(event);
    if( event ) {
      if( event.startDate && event.endDate ) {
        this.queryParamsDate = "startDate=" + event.startDate + "&endDate=" + event.endDate;
      }
    }
    // this.getAllRates(this.queryParamsSearch, this.queryParamsDate ? this.queryParamsDate : null , this.selectedUser ? this.selectedUser.idMerchant : null)
    if( this.table ) {
      this.table.offset = 0;
    }
  }

  getQueryParamsOfSearch(searchItems) {
    let queryParamsSearch = "";
    if (searchItems.keyword)
      queryParamsSearch += "keyword=" + searchItems.keyword

    if (searchItems.searchFromSelect){
      if (searchItems.searchFromSelect.length > 0) {
        if (searchItems.keyword)
          queryParamsSearch += "&"
        queryParamsSearch += "profile=" + searchItems.searchFromSelect.join()
      }
    }
    return queryParamsSearch;
  }

  private getQueryParams() {
    let queryParamsSearch = "";
    for (let key in this.searchForm.controls) {
      if (this.searchForm.controls[key].value) {
        if (key != 'idMerchant') {
          /*if (key.startsWith('dateRange')) {
            if (this.searchForm.controls[key].value.startDate && this.searchForm.controls[key].value.endDate) {
              if (queryParamsSearch) {
                queryParamsSearch += "&"
              }
              queryParamsSearch += "startDate" + key.replace('dateRange', '') + "=" + this.searchForm.controls[key].value.startDate.format("YYYY-MM-DD");
              queryParamsSearch += "&"
              queryParamsSearch += "endDate" + key.replace('dateRange', '') + "=" + this.searchForm.controls[key].value.endDate.format("YYYY-MM-DD");
            }
          }*/
          if (key.startsWith('date')) {
            if (key.startsWith('dateFrom') && this.searchForm.controls[key].value.startDate &&
              this.searchForm.controls['dateTo' + key.replace('dateFrom', '')].value.endDate) {
              if (queryParamsSearch) {
                queryParamsSearch += "&"
              }
              queryParamsSearch += "startDate" + key.replace('dateFrom', '') + "=" + this.searchForm.controls[key].value.startDate.format("YYYY-MM-DD");
            } else if (key.startsWith('dateTo') && this.searchForm.controls['dateFrom' + key.replace('dateTo', '')].value.startDate &&
              this.searchForm.controls[key].value.endDate) {
              if (queryParamsSearch) {
                queryParamsSearch += "&"
              }
              queryParamsSearch += "endDate" + key.replace('dateTo', '') + "=" + this.searchForm.controls[key].value.endDate.format("YYYY-MM-DD");
            } else if (key.startsWith('dateRange') && this.searchForm.controls[key].value) {
              if (queryParamsSearch) {
                queryParamsSearch += "&"
              }
              queryParamsSearch += "startDate" + key.replace('dateRange', '') + "=" + moment(this.searchForm.controls[key].value[0]).format("YYYY-MM-DD") + "&" +
                "endDate" + key.replace('dateRange', '') + "=" + moment(this.searchForm.controls[key].value[1]).format("YYYY-MM-DD");
            }
          } else {
            if (Array.isArray(this.searchForm.controls[key].value)) {
              if (!!this.searchForm.controls[key].value.length) {
                if (queryParamsSearch) {
                  queryParamsSearch += "&"
                }
                queryParamsSearch += key + "=" + this.searchForm.controls[key].value.join(',')
              }
            } else {
              if (queryParamsSearch) {
                queryParamsSearch += "&"
              }
              queryParamsSearch += key + "=" + this.searchForm.controls[key].value
            }
          }
        }
      }
    }

    let excludeExpired = true;
    let onlyRefunded = false;
    let instalmentStatus = null;
    for (let key in this.sortForm.controls) {
      if (this.sortForm.controls[key].value) {
        switch (key) {
          case 'viewExpired':
            excludeExpired = !this.sortForm.controls[key].value;
            break;
          case 'viewRefunded':
            onlyRefunded = this.sortForm.controls[key].value;
            break;
          case 'viewDisputed':
            if (!instalmentStatus) {
              instalmentStatus = [];
            }
            const statusDisputed = this.sortForm.controls['statusDisputed'].value;
            if (!statusDisputed) {
              const disputeStatuses = ['DISPUTE', 'DISPUTED'];
              instalmentStatus.push(...disputeStatuses);
            } else {
              instalmentStatus.push(...statusDisputed);
            }
            break;
        }
      }
    }
    if (excludeExpired) {
      if (queryParamsSearch) {
        queryParamsSearch += "&"
      }
      queryParamsSearch += "excludeExpired=" + excludeExpired
    }
    if (onlyRefunded) {
      if (queryParamsSearch) {
        queryParamsSearch += "&"
      }
      queryParamsSearch += "onlyRefunded=" + onlyRefunded
    }
    if (instalmentStatus) {
      if (queryParamsSearch) {
        queryParamsSearch += "&"
      }
      queryParamsSearch += "instalmentStatus=" + instalmentStatus
    }

    return queryParamsSearch;
  }

  private getIdMerchant() {
    let idMerchant = null;
    if (this.searchForm.controls['idMerchant'].value) {
      idMerchant = this.searchForm.controls['idMerchant'].value
    }
    return idMerchant;
  }

  prepareForSearch() {
    if (this.searchForm.valid) {
      this.tablePage = 1;
      this.getAllRates( this.getQueryParams(), 
        this.selectedUser ? this.selectedUser.idMerchant : this.getIdMerchant() );
      if( this.table ) {
        this.table.offset = 0;
      }
    }
  }

  prepareForCancel() {
    this.searchForm.reset({
      //dateRangeExpiration: { startDate: moment(), endDate: moment().add(30, 'days') },
      //dateFromExpiration: { startDate: moment(), endDate: moment() },
      //dateToExpiration: { startDate: moment().add(30, 'days'), endDate: moment().add(30, 'days') },
      dateRangeExpiration: [ moment().toDate(), moment().add(30, 'days').toDate() ],
      //dateRangeCollection: null,
      //dateFromCollection: null,
      //dateToCollection: null,
      dateRangeCollection: null,
      //dateRangePayment: null,
      //dateFromPayment: null,
      //dateToPayment: null,
      dateRangePayment: null,
      idMerchant: this.selectedUser ? this.selectedUser.idMerchant : null
    });
    this.searchForm.markAsPristine();
    this.prepareForSearch();
  }

  intervalEvent(event) {
    this.queryParamsDate = event;
    let selectedUser = JSON.parse(localStorage.getItem("selectedUser"))
    // this.getAllRates(this.queryParamsSearch, this.queryParamsDate, selectedUser ? selectedUser.idMerchant : null)
  }

  initFirstCallRangeDates( return_type = null) {
    var date = new Date();
    let today = this.formatDate(date);
    date.setDate(date.getDate() + 30);
    let beforeAWeek = this.formatDate(date);
    let beforeDate = this.formatDate(date);
    let queryParamsDate : any = "startDate=" + today + "&endDate=" + beforeDate;

    if ( return_type == null ){
      queryParamsDate = "startDate=" + today + "&endDate=" + beforeDate;
    } else if ( return_type == "obj" ) {
      queryParamsDate = { date : { startDate : today , endDate : beforeDate } }
    } else if ( return_type == "chip" ) {
      queryParamsDate = today + "/" + beforeDate
    }

    return queryParamsDate;
  }
  
  hasSelectedAlias() {
    var cur_use = JSON.parse(localStorage.getItem('selectedUser'))
    if ( cur_use != null && this.selectedUser == null ) {
      this.selectedUser = cur_use
      return true
    } else if ( cur_use != null && this.selectedUser != null ) {
      if( parseInt(this.selectedUser.idMerchant) !== parseInt(cur_use.idMerchant) ) {
        this.selectedUser = cur_use;
        this.oldVal = false
        return true
      }
    } else {
      this.selectedUser = null;
      return false
    }
  }

  /**
   * This function are utilities
   * @param date
   */
  formatDate(date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }

  statusSelectedEvent(event, row) {
    row.statusSelectOpened = false
    row.status = event.name
  }

  hideSelectEvent(row) {
    row.statusSelectOpened = false
  }

  async export() {
    const rows = await this.getAllRates( this.getQueryParams(), 
      this.selectedUser ? this.selectedUser.idMerchant : this.getIdMerchant(),
      true );
    var data = [];
    var rateHeader = {};
    if (localStorage.getItem('profile') == 'SUPERADMIN' || localStorage.getItem('profile') == 'RESELLER') {
      rateHeader = {
        merchant: 'Commerciante'
      }
    }
    rateHeader = {
      ...rateHeader,
      saleName: 'Nome vendita associata',
      name: 'Cognome Nome cliente',
      expiryDate: 'Data scadenza rata',
      tsPayment: 'Data incasso rata',
      instalmentAmount: 'Importo rata',
      instalmentFeeAmount: 'Importo commissioni rata',
      instalmentFixedFeeAmount: 'Importo commissioni fisse rata',
      instalmentPercentageFeeAmount: 'Importo commissioni % rata',
      instalmentStatus: 'Stato rata',
      paymentMethodType: 'Metodo di pagamento'
    }
    data.push(rateHeader)
    for (let i = 0; i < rows.length; i++) {
      const element = rows[i];
      var rate = {};
      if (localStorage.getItem('profile') == 'SUPERADMIN' || localStorage.getItem('profile') == 'RESELLER') {
        rate = {
          merchant: element.merchantCompanyName
        }
      }
      rate = {
        ...rate,
        saleName: element.service ? element.service : 'Non disponibile',
        name: element.fullName ? element.fullName : element.surname + ' ' + element.name,
        expiryDate: element.expiryDate,
        tsPayment: element.tsPayment ? element.tsPayment : '',
        instalmentAmount: element.amount,
        instalmentFeeAmount: element.feeAmount,
        instalmentFixedFeeAmount: element.fixedFeeAmount ? element.fixedFeeAmount : '',
        instalmentPercentageFeeAmount: element.percentageFeeAmount ? element.percentageFeeAmount : '',
        instalmentStatus: element.instalmentStatus,
        paymentMethodType: element.paymentMethodType ?
          (element.paymentMethodType == 'card' || element.paymentMethodType == 'card_present' ?
              'Carta ' + element.paymentMethodCode + (element.paymentMethodCountry ? ' (' + element.paymentMethodCountry + ')' : '') +
                ' ' + (element.paymentMethodLast4 || '----') + ' (' + (this.decimalPipe.transform(element.paymentMethodExpMonth, '2.0-0') || '--') +
                '/' + (element.paymentMethodExpYear || '----') + ')' :
            (element.paymentMethodType == 'sepa_debit' ?
              'Sepa' + (element.paymentMethodCountry ? ' (' + element.paymentMethodCountry + ')' : '') + ' ' + (element.paymentMethodLast4 || '----') :
            (element.paymentMethodType == 'klarna' ? 'Klarna' :
            (element.paymentMethodType == 'paypal' ? 'Paypal' : '')))) : ''
      }
      data.push(rate)
    }
    this.excelService.export(data, 'Analisi Rate' + new Date().getTime())
  }

  redirectToEdit(row) {
  }

  chipDeleted() {
    this.deletedChip.emit()
  }

  isValChanged( old , current ){
    if( old == current ) {
      return false;
    } else {
      return true
    }
  }

  onExclusionChange( event ) {
    this.includiScaduti = event
    // this.initRateTable('1')
    if( event != true ) {
      this.rows = this.excludeExpiredRates()
    } else {
      if( this.allRates ) {
        this.rows = this.allRates;
      }
    }
  }

  isExpired( element ) {
    if( element.instalmentStatus == "PLANNED" && moment( element.expiryDate , 'YYYY-MM-DD' ).isBefore(moment() , 'day') ) {
      return true
    } else {
      return false
    }
  }

  isSaleExpired( element ) {
    let expirationDate = element.saleStartDate
    if (element.paymentType == 'VARIABLE') {
      expirationDate = element.saleEndDate
    }
    if( ( element.saleStatus == "NEW" && ( moment(expirationDate, 'YYYY-MM-DD').isBefore(moment() , 'day') ) ) ||
      element.status == "EXPIRED_MANDATORY") {
      return true
    } else {
      return false
    }
  }

  isSaleDetailExpired( element ) {
    let expirationDate = element.startDate
    if (element.paymentType == 'VARIABLE') {
      expirationDate = element.endDate
    }
    if( ( element.status == "new" && ( moment(expirationDate, 'YYYY-MM-DD').isBefore(moment() , 'day') ) ) ||
      element.status == "expired_mandatory") {
      return true
    } else {
      return false
    }
  }

  isRefunded( element ) {
    if ( ( element.instalmentStatus == 'PAID' || element.instalmentStatus == 'ONGOING_INQUIRY' || element.instalmentStatus == 'CLOSED_INQUIRY') &&
      element.refundedAmount > 0 ) {
      return true
    } else {
      return false
    }
  }

  isDisputed( element ) {
    if ( element.instalmentStatus == 'DISPUTE' || element.instalmentStatus == 'DISPUTED' ) {
      return true
    } else {
      return false
    }
  }

  onExpiredExclusionChange( event ) {
    this.sortForm.controls.viewExpired.setValue(event);
    /*this.onFlagExclusionChange();*/
    this.prepareForSearch();
  }

  onRefundedExclusionChange( event ) {
    this.sortForm.controls.viewRefunded.setValue(event);
    /*this.onFlagExclusionChange();*/
    this.prepareForSearch();
  }

  onDisputedExclusionChange( event ) {
    this.sortForm.controls.viewDisputed.setValue(event);
    /*this.onFlagExclusionChange();*/
    this.prepareForSearch();
  }

  onFlagExclusionChange() {
    if (!this.sortForm.controls.viewExpired.value || this.sortForm.controls.viewRefunded.value || this.sortForm.controls.viewDisputed.value) {
      this.rows = this.excludeFlagRates()
    } else {
      if( this.allRates ) {
        this.rows = this.allRates;
      }
    }
  }

  sendReminder( row ) {
    this.logger.log( 'sendReminder()' , row , 200)
    // this.getSalesDetails( row.idSale, row )
    let idInstalment : any = row.id;
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    this.salesService.getRateDetails(row.idSale).subscribe((res: any) => {
      row.email = res.email;
      console.log( 'getRateDetails - email' , res.email )

      if( row.first ) {
        this.sendMailInstalment( row )
      } else {
        this.salesService.generateInstalmentCode( row.id ).subscribe((resp) => {
          this.sendMailInstalment( row )
        })
      }

    }) 
    
    /* this.salesService.getSalesDetails( row.idSale ).subscribe((res)=> {
      
      idInstalment = res.results[0].idInstalment

      this.salesService.sendInstalmentMail(idInstalment).subscribe((response)=> {
        swal.close()
        if( response === 'OK' ) {
          swal( this.translate.instant('sale.send_instalment_reminder_ok') , this.translate.instant('sale.sended_instalment_ok' , {customer_email : row.email} ) , "success" )
        } else {
          swal( "Error" , "" , "error" )
        }
      })

    }) */
    
  }

  canViewPaymentLink() {
    if( localStorage.getItem('profile') != 'SUPERADMIN' && localStorage.getItem('profile') != 'RESELLER' ) {
      this.viewPaymentLink = this.merchantDetail.linkFirstPayment
    } else if( localStorage.getItem('profile') == 'SUPERADMIN' ) {
      this.viewPaymentLink = true;
    } else {
      this.viewPaymentLink = false;
    }
  }

  canSendReminder( element ) {
    if( element.instalmentStatus == "ERROR" || element.instalmentStatus == "UNSOLVED" || localStorage.getItem('profile') == 'SUPERADMIN' ) {
      this.enableSendReminder = true 
      return true
      // if( element.code ) {

      // }
    } else {
      return false
    }
  }

  canRefundPayment( element ) {
    return element.amount > element.refundTotalAmount && localStorage.getItem("profile") == "SUPERADMIN";
  }

  getMerchantDetail() {
    this.sellerService.getSellerDetails( localStorage.getItem('idMerchant') ).subscribe((resp) => {
      this.merchantDetail = resp
      this.canViewPaymentLink()
      this.getMerchantStatusTos();
    })
  }
  
  getMerchantStatusTos() {
    this.sellerService.statusTos( localStorage.getItem('idMerchant') ).subscribe( (res) => {
      this.merchantDetail.tos_accepted = res.accepted
    })
  }
  
  getSaleFrozenExpirationDays(row) {
    const datesDiffMillis = moment().diff(moment(new Date(row.tsStatusLastModified)));
    const maxDaysMillis = moment.duration(this.saleFrozenMaxDays, 'days').as('milliseconds');
    return Math.round(moment.duration(maxDaysMillis - datesDiffMillis).as('days'));
  }

  sendMailInstalment( row ) {
    let idInstalment : any = row.id;
    this.salesService.sendInstalmentMail(idInstalment).subscribe((response)=> {
      // swal.close()
      if( response === 'OK' ) {
        swal( this.translate.instant('sale.send_instalment_reminder_ok') , this.translate.instant('sale.sended_instalment_ok' , {customer_email : row.email} ) , "success" )
      } else {
        swal( "Error" , "" , "error" )
      }
    })
  }

  showLinkPayment( row ) {
    swal({
      title: '',
      onOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    let instalmentCode 
    // if( row.code && row.code != null ) {
    if ( row.first ) {
      instalmentCode = row.code
      this.showSwalLinkPayment( instalmentCode )
    } else {
      // instalmentCode = this.generateInstalmentCode( row )
      this.salesService.generateInstalmentCode( row.id ).subscribe((resp) => {
        instalmentCode = resp.data.code
        if( ( row.saleStatus == 'NEW' || row.saleStatus == 'ACTIVE' ) && row.instalmentStatus == 'PLANNED' ) {
          this.showSwalLinkPayment( instalmentCode )
        }
      })
    }
  }

  generateInstalmentCode( row ) {
    this.salesService.generateInstalmentCode( row.id ).subscribe((resp) => {
      // console.log('generated instalment code' , resp.data.code )
      return resp.data.code
    })
  }

  showSwalLinkPayment( data ) {
    swal({
      title : 'Link generato con successo',
      html: '<em title="'+ this.translate.instant('sale.tooltip_copy_instalmentcode') +'">' + location.host + '/make-payment/' + data + '</em>',
      type: 'success' ,
      allowOutsideClick : false,
      allowEscapeKey : false
    })
  }

  closeInstalment( data ) {
    swal({
      title: this.translate.instant('sale.swal.close_instalment_title'), 
      text: this.translate.instant('sale.swal.close_instalment_text'), 
      type: "info",
      showCancelButton: true,
      cancelButtonText: this.translate.instant('general.back'),
      confirmButtonText: this.translate.instant('general.continue')
    }).then((result) => {
      if( result.value ) {
        setTimeout(() => swal({
          title: '',
          onOpen: (() => swal.showLoading()),
          allowEscapeKey: false,
          allowOutsideClick: false,
        }), 200) // WORKAROUND -> https://github.com/sweetalert2/sweetalert2/issues/609#issue-255084207
        this.appService.editElement('/instalment/close/'+data.id, null).subscribe((resp) => {
          setTimeout(() => swal.hideLoading(), 200)
          if( resp.outcome && resp.outcome.success ) {
            swal({
              type: "success",
              text: this.translate.instant('sale.swal.close_instalment_success')
            }).then((result) => {
              this.prepareForSearch()
            })
          } else {
            swal({
              type: "error",
              text: this.translate.instant('sale.swal.close_instalment_error')
            }).then((result) => {
              this.prepareForSearch()
            })
          }
        })
      } else if( result.dismiss ) {
        swal.noop()
      }
    })
  }

  hasActionToShow( data ) {
    if( ( data.instalmentStatus == 'PLANNED' && ( data.saleStatus == 'NEW' || data.saleStatus == 'ACTIVE' ) ) && this.viewPaymentLink ) {
      return true;
    } else if( ( data.instalmentStatus == 'ERROR' || data.instalmentStatus == 'UNSOLVED' ) && this.viewPaymentLink && this.canSendReminder(data) ) {
      return true;
    } else if( ( data.instalmentStatus == 'ERROR' && ( data.saleStatus == 'NEW' || data.saleStatus == 'ACTIVE' ) ) && (this.profile == 'SUPERADMIN' || (this.merchantDetail && this.merchantDetail.tos_accepted && this.merchantDetail.state == 'ACTIVE')) ) {
      return true;
    } else if( data.closable && (this.profile == 'SUPERADMIN' || (this.merchantDetail && this.merchantDetail.tos_accepted && this.merchantDetail.state == 'ACTIVE')) ) {
      return true;
    } else if( data.paymentType != 'RECHARGE_EXT_REFUND' && ( data.instalmentStatus == 'PAID' || data.instalmentStatus == 'ONGOING_INQUIRY' || data.instalmentStatus == 'CLOSED_INQUIRY' ) && this.canRefundPayment(data) ) {
      return true;
    } else {
      return false;
    }
  }

  openModalPaymentLink(data) {
    this.salesService.getInstalmentPaymentLink( data.id ).subscribe((resp) => {
      if (resp.data) {
        this.showSwalPaymentLink( resp.data )
      } else {
        swal( this.translate.instant('swal.title_Error') , this.translate.instant('sale.view_payment_link_error') , "error")
      }
    })
  }  

  showSwalPaymentLink( data ) {
    swal({
      title : this.translate.instant('sale.payment_link'),
      html: '<em title="'+ this.translate.instant('sale.tooltip_copy_payment_link') +'">' + data + '</em>',
      type: 'info' ,
      allowOutsideClick : false,
      allowEscapeKey : false
    })
  }

  get refundFormMinAmount() {
    return this.minInstalmentAmount;
  }

  get refundFormMaxAmount() {
    return this.instalmentToBeRefunded.amount - this.instalmentToBeRefunded.refundTotalAmount - this.minInstalmentAmount;
  }

  openModalPaymentRefund(data) {
    this.instalmentToBeRefunded = data;
    this.refundForm.reset({
      type: RefundType.FULL,
      amount: null
    });
    this.refundForm.get('amount').setValidators([Validators.min(this.refundFormMinAmount), Validators.max(this.refundFormMaxAmount)]);
    this.refundForm.get('amount').updateValueAndValidity();
    this.modalRef = this.modalService.show(this.modalTemplate , { class: 'modal-lg' } )
  }

  onChangeRefundType() {
    this.refundForm.patchValue({
      amount: null
    });
    if (this.refundForm.get('type').value == RefundType.PARTIAL) {
      this.refundForm.get('amount').setValidators([Validators.min(this.refundFormMinAmount), Validators.max(this.refundFormMaxAmount), Validators.required]);
      this.refundForm.get('amount').updateValueAndValidity();
    } else {
      this.refundForm.get('amount').setValidators([Validators.min(this.refundFormMinAmount), Validators.max(this.refundFormMaxAmount)]);
      this.refundForm.get('amount').updateValueAndValidity();
    }
  }

  refundInstalment() {
    swal({
      title: this.translate.instant('sale.swal.refund_instalment_title'), 
      text: this.translate.instant('sale.swal.refund_instalment_text'), 
      type: "warning",
      showCancelButton: true,
      cancelButtonText: this.translate.instant('general.back'),
      confirmButtonText: this.translate.instant('general.continue'),
    }).then((result) => {
      if( result.value ) {
        setTimeout(() => swal({
          title: '',
          onOpen: (() => swal.showLoading()),
          allowEscapeKey: false,
          allowOutsideClick: false,
        }), 200) // WORKAROUND -> https://github.com/sweetalert2/sweetalert2/issues/609#issue-255084207
        const data = { amount: this.refundForm.value.amount }
        this.appService.newElement('/payment/instalment/refund/'+this.instalmentToBeRefunded.id, data).subscribe((resp) => {
          setTimeout(() => swal.hideLoading(), 200)
          if( resp.outcome.success ) {
            swal({
              type: "success",
              text: this.translate.instant('sale.swal.refund_instalment_success')
            }).then((result) => {
              this.prepareForSearch()
              this.modalRef.hide()
            })
          } else {
            let errorMessage = null;
            switch (resp.outcome.code) {
              case "0037":
              case "0039":
              case "0040":
              case "0041":
              case "0042":
              case "0043":
              case "0052":
                errorMessage = this.settings.manageErrorMsg(resp.outcome)
                break;
              case "0038":
                swal({
                  html: this.settings.manageErrorMsg(resp.outcome) + '<br><br>' + this.translate.instant('sale.swal.refund_instalment_topup_sale_text'),
                  type: "error",
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#aaa',
                  cancelButtonText: this.translate.instant('swal.lbl_btn_cancel'),
                  confirmButtonText: this.translate.instant('sale.swal.lbl_btn_confirm')
                })
                .then((result) => {
                  if( result.value ) {
                    setTimeout(() => swal({
                      title: '',
                      onOpen: (() => swal.showLoading()),
                      allowEscapeKey: false,
                      allowOutsideClick: false,
                    }), 200) // WORKAROUND -> https://github.com/sweetalert2/sweetalert2/issues/609#issue-255084207
                    const topUpData = {
                      idInstalmentRef: this.instalmentToBeRefunded.id,
	                    saleAmount: data.amount
                    }
                    this.salesService.insertTopUpSale( topUpData ).subscribe((res)=> {
                      setTimeout(() => swal.hideLoading(), 200)
                      if (res.outcome.success) {
                        swal({
                          type: "success",
                          text: this.translate.instant('sale.swal.refund_instalment_topup_sale_created_success')
                        }).then((result) => {
                          this.prepareForSearch()
                          this.modalRef.hide()
                        })
                      } else {
                        swal({
                          type: "error",
                          text: this.translate.instant('sale.swal.refund_instalment_topup_sale_created_error')
                        }).then((result) => {
                          this.prepareForSearch()
                          this.modalRef.hide()
                        })
                      }
                    })
                  } else {
                    swal.noop()
                  }
                })
                break;
              default:
                errorMessage = this.translate.instant('sale.swal.refund_instalment_error')
                break;
            }
            if (errorMessage) {
              swal({
                type: "error",
                text: errorMessage
              }).then((result) => {
                this.prepareForSearch()
                this.modalRef.hide()
              })
            }
          }
        })
      } else if( result.dismiss ) {
        swal.noop()
      }
    })
  }

  canCompleteRefundPayment( element ) {
    return localStorage.getItem("profile") == "SUPERADMIN";
  }

  completeRefundInstalment( data ) {
    swal({
      title: this.translate.instant('sale.swal.complete_refund_instalment_title'), 
      text: this.translate.instant('sale.swal.complete_refund_instalment_text'), 
      type: "warning",
      showCancelButton: true,
      cancelButtonText: this.translate.instant('general.back'),
      confirmButtonText: this.translate.instant('general.continue')
    }).then((result) => {
      if( result.value ) {
        setTimeout(() => swal({
          title: '',
          onOpen: (() => swal.showLoading()),
          allowEscapeKey: false,
          allowOutsideClick: false,
        }), 200) // WORKAROUND -> https://github.com/sweetalert2/sweetalert2/issues/609#issue-255084207
        this.appService.editElement('/payment/instalment/refund/complete/'+data.id, null).subscribe((resp) => {
          setTimeout(() => swal.hideLoading(), 200)
          if( resp.outcome && resp.outcome.success ) {
            swal({
              type: "success",
              text: this.translate.instant('sale.swal.complete_refund_instalment_success')
            }).then((result) => {
              this.prepareForSearch()
            })
          } else {
            let errorMessage = null;
            switch (resp.code) {
              case "0039":
              case "0040":
              case "0041":
              case "0042":
                errorMessage = this.settings.manageErrorMsg(resp)
                break;
              default:
                errorMessage = this.translate.instant('sale.swal.complete_refund_instalment_error')
                break;
            }
            swal({
              type: "error",
              text: errorMessage
            }).then((result) => {
              this.prepareForSearch()
            })
          }
        })
      } else if( result.dismiss ) {
        swal.noop()
      }
    })
  }

  openInfoModal() {
    this.modalRef = this.modalService.show(this.infoModalTemplate , { class: 'modal-lg' } )
  }

  saveHideInfo() {
    this.modalRef.hide();
    setTimeout(() => {
      this.hideInstalmentInfo = this.infoForm.value.hideInfo;
      localStorage.setItem('hideInstalmentInfo',JSON.stringify(this.hideInstalmentInfo))
    }, 200)
  }

  ngOnDestroy(){
    this.notifySelectedUserChange.unsubscribe()
  }
}
